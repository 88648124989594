import {CalculatorObj} from "../types/CalculatorObj"
import {MINIMUMWAGE, LEGAL_RELATIONSHIP, MONTH, MONTH_FIELD, MONTH_SHORT} from "./enums"

export const legalRelationships = [LEGAL_RELATIONSHIP.MAIN_OCCUPATION, LEGAL_RELATIONSHIP.SIDE_JOB, LEGAL_RELATIONSHIP.ADDITIONAL_RETIRED]

export const costRatios = [40, 80, 90]

export const minimumWageOptions = [MINIMUMWAGE.MINIMUMWAGE, MINIMUMWAGE.GUARANTEED_MINIMUMWAGE]

export const ipaValues = [1, 2]

export const months = [MONTH.JANUARY, MONTH.FEBRUARY, MONTH.MARCH, MONTH.APRIL, MONTH.MAY, MONTH.JUNE, MONTH.JULY, MONTH.AUGUST,
    MONTH.SEPTEMBER, MONTH.OCTOBER, MONTH.NOVEMBER, MONTH.DECEMBER]

export const monthShort = [MONTH_SHORT.JANUARY, MONTH_SHORT.FEBRUARY, MONTH_SHORT.MARCH, MONTH_SHORT.APRIL, MONTH_SHORT.MAY, MONTH_SHORT.JUNE, MONTH_SHORT.JULY, MONTH_SHORT.AUGUST,
    MONTH_SHORT.SEPTEMBER, MONTH_SHORT.OCTOBER, MONTH_SHORT.NOVEMBER, MONTH_SHORT.DECEMBER]

export const monthFields = [MONTH_FIELD.YEARLY, MONTH_FIELD.JANUARY, MONTH_FIELD.FEBRUARY, MONTH_FIELD.MARCH, MONTH_FIELD.APRIL, MONTH_FIELD.MAY, MONTH_FIELD.JUNE, MONTH_FIELD.JULY, MONTH_FIELD.AUGUST,
    MONTH_FIELD.SEPTEMBER, MONTH_FIELD.OCTOBER, MONTH_FIELD.NOVEMBER, MONTH_FIELD.DECEMBER]

export const getStartingMonthIndexInMonths = (calculatorObj: CalculatorObj) => months.findIndex(month => month === calculatorObj.startingMonth)

export const dates = [

    // new Date().getFullYear()-1 + ' ' + MONTH.JANUARY,
    // new Date().getFullYear()-1 + ' ' + MONTH.FEBRUARY,
    // new Date().getFullYear()-1 + ' ' + MONTH.MARCH,
    // new Date().getFullYear()-1 + ' ' + MONTH.APRIL,
    // new Date().getFullYear()-1 + ' ' + MONTH.MAY,
    // new Date().getFullYear()-1 + ' ' + MONTH.JUNE,
    // new Date().getFullYear()-1 + ' ' + MONTH.JULY,
    // new Date().getFullYear()-1 + ' ' + MONTH.AUGUST,
    // new Date().getFullYear()-1 + ' ' + MONTH.SEPTEMBER,
    // new Date().getFullYear()-1 + ' ' + MONTH.OCTOBER,
    // new Date().getFullYear()-1 + ' ' + MONTH.NOVEMBER,
    // new Date().getFullYear()-1 + ' ' + MONTH.DECEMBER,
    new Date().getFullYear() + ' ' + MONTH.JANUARY,
    new Date().getFullYear() + ' ' + MONTH.FEBRUARY,
    new Date().getFullYear() + ' ' + MONTH.MARCH,
    new Date().getFullYear() + ' ' + MONTH.APRIL,
    new Date().getFullYear() + ' ' + MONTH.MAY,
    new Date().getFullYear() + ' ' + MONTH.JUNE,
    new Date().getFullYear() + ' ' + MONTH.JULY,
    new Date().getFullYear() + ' ' + MONTH.AUGUST,
    new Date().getFullYear() + ' ' + MONTH.SEPTEMBER,
    new Date().getFullYear() + ' ' + MONTH.OCTOBER,
    new Date().getFullYear() + ' ' + MONTH.NOVEMBER,
    new Date().getFullYear() + ' ' + MONTH.DECEMBER,

    // 2024+' '+MONTH.JANUARY,
    // 2024+' '+MONTH.FEBRUARY,
    // 2024+' '+MONTH.MARCH,
    // 2024+' '+MONTH.APRIL,
    // 2024+' '+MONTH.MAY,
    // 2024+' '+MONTH.JUNE,
    // 2024+' '+MONTH.JULY,
    // 2024+' '+MONTH.AUGUST,
    // 2024+' '+MONTH.SEPTEMBER,
    // 2024+' '+MONTH.OCTOBER,
    // 2024+' '+MONTH.NOVEMBER,
    // 2024+' '+MONTH.DECEMBER,
]

export enum SZJA_PERIOD {
    FREE = 'SZJA mentes időszak',
    PARTIALLY_FREE = 'Részlegesen SZJA mentes hónap',
    OBLIGATORY = 'SZJA köteles időszak'
}

export const minimálBér_2023 = 232000;
export const garantáltBérminimum_2023 = 296400;
export const minimálBér_2023_12 = 266800;
export const garantáltBérminimum_2023_12 = 326000;
export const SZJAMentességHatárértékFt = 1600800;


export let minimálBér = 232000;
export const setMinimálBér = (newValue: number) => {
    minimálBér = newValue;
};
export let garantáltBérminimum = 296400;
export const setGarantáltBérminimum = (newValue: number) => {
    garantáltBérminimum = newValue;
};
export let SZOCHOMinimálbér = minimálBér * 1.125;
export const setSZOCHOMinimálbér = (newValue: number) => {
    SZOCHOMinimálbér = newValue;
};
export let SZOCHOGarantáltBérminimum = garantáltBérminimum * 1.125;
export const setSZOCHOGarantáltBérminimum = (newValue: number) => {
    SZOCHOGarantáltBérminimum = newValue;
};
export let TBMinimálBér = minimálBér * 1;
export const setTBMinimálBér = (newValue: number) => {
    TBMinimálBér = newValue;
};

export let TBGarantáltBérminimum = garantáltBérminimum * 1;
export const setTBGarantáltBérminimum = (newValue: number) => {
    TBGarantáltBérminimum = newValue;
};

export let SZJASzázalék = 15;
export let SZOCHOSzázalék = 13;
export let TBSzázalék = 18.5;
export const monthsAsPropertyNames: string[] = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];


