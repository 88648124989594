import { FormControl, FormLabel, InputAdornment, TextField } from "@mui/material"
import NumberFormat from "react-number-format"

type Props = {
    handleChangeNumber?: (name: string, value: number) => Promise<void>
    label?: string
    value: number
    name: string
    handleInput?: (e: any, name: string) => Promise<void>
    inputProps?: string
    monthNumber?: number
    startMonthNumber?: number
}

const NumberInput = ({ handleChangeNumber, label, value, name, handleInput, inputProps, monthNumber, startMonthNumber }: Props) => {
    
    return (
        <FormControl fullWidth>
            <FormLabel id="demo-controlled-radio-buttons-group" sx={{ fontWeight: 'bold', fontSize: '1em', textAlign: 'center', whiteSpace: 'nowrap' }}>{label}</FormLabel>
            <NumberFormat customInput={TextField}
                decimalScale={0}
                name={name}
                value={value}          
                size='small' 
                inputProps={{maxLength:12}}
                       
                thousandSeparator={' '} 
                onValueChange={
                    (values: any) => {
                        const { floatValue} = values;
                        
                        if(handleChangeNumber) handleChangeNumber(name, floatValue);
                    }
                }
                InputProps={{
                    startAdornment: inputProps && (
                        <div style={{display:'flex',flexDirection:'column',fontSize: '16px', justifyContent:'center',alignContent:'center', backgroundColor:'#E1E8EB',paddingLeft:10,height:40,minWidth:'110px',marginLeft:-13,border:'0px solid green',marginRight:15}}>
                        <InputAdornment position="start"><b>{inputProps}:</b></InputAdornment>
                        </div>
                      ),
                    endAdornment: (
                        <InputAdornment position="end">Ft</InputAdornment>
                    )
                  }}
                onInput={(e: any) => {
                        if(handleInput) handleInput(e, name)
                    }
                }
                autoComplete="off"/>
        </FormControl>
    )
}

export default NumberInput