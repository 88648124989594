import React, { useState } from "react"
import { CalculatorObj} from "../types/CalculatorObj"
import translator from "../translator";
import {LEGAL_RELATIONSHIP} from "../globals/enums";
import SelectInput from "./input/SelectInput";
import NumberInput from "./input/NumberInput";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import {  dates, legalRelationships, costRatios, ipaValues, minimumWageOptions } from "../globals/constants";

type Props = {
    calculatorObj: CalculatorObj
    isMonthly:boolean,
    setCalculatorObj: React.Dispatch<React.SetStateAction<CalculatorObj>>
    showMonthlyWagesTab: () => void
    displayTable: () => void
    setMonthly: () => void
    showButton: boolean
}

const Calculator = ({ setMonthly, isMonthly, calculatorObj, setCalculatorObj, showMonthlyWagesTab, showButton, displayTable }: Props) => {
    const [displayAdvancedButton, setDisplayAdvancedButton] = useState(false)

    const [dailyOrYearlyTitle, setDailyOrYearlyTitle] = useState(" havi ")
    const changeButtonTitle = () => {

        if (dailyOrYearlyTitle === " éves ") {
            setDailyOrYearlyTitle(" havi ");
            document.getElementById(":r1:")?.focus()
        } else {
            setDailyOrYearlyTitle(" éves ");
            // eslint-disable-next-line no-implied-eval
            setTimeout("document.querySelectorAll('input[type=text]')[1]?.focus()", 0)
        }

    }
    
    const handleChange = async (e: any) => {
        setCalculatorObj(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeYear = async (e: any) => {
        setCalculatorObj(prevState => ({
            ...prevState,
            year: (e.target.value).split(' ')[0],
            startingMonth: (e.target.value).split(' ')[1],
            dateDisplay: e.target.value
        }))
    }

    const handleChangeNumber = async (name: string, value: number) => {

        setCalculatorObj(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    
    return (
        <Grid>
            <Grid container sx={{ marginTop: '10px', alignContent: 'center', width: '100%' }} display="inline-flex">
                    <Grid item xs={12} sm={6} md={4}  sx={{ padding: '10px' }}>
                        <SelectInput name={'legalRelationShip'}
                            handleChange={handleChange} label={translator('calculator.legalRelationship')}
                            hasTextInParenthesis
                            value={calculatorObj.legalRelationShip} defaultValue={LEGAL_RELATIONSHIP.MAIN_OCCUPATION}
                            options={legalRelationships} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ padding: '10px', minWidth: '150px' }}  >
                        <SelectInput name={'useMinimumWage'}
                            handleChange={handleChange} label={translator('calculator.useMinimumWage')} 
                            hasTextInParenthesis
                            value={calculatorObj.useMinimumWage} defaultValue={1}
                            options={minimumWageOptions} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}  sx={{ padding: '10px', minWidth: '150px' }}  >
                        <SelectInput name={'year'}
                            handleChange={handleChangeYear} label={translator('calculator.year')}
                            value={calculatorObj.dateDisplay} defaultValue={dates[0]}
                            options={dates} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}  sx={{ padding: '10px', minWidth: '150px' }}  >
                        <NumberInput name={'expectedSalesPerYear'}
                            label={translator('calculator.expectedSalesPerYear')}
                            value={calculatorObj.expectedSalesPerYear}
                            handleChangeNumber={handleChangeNumber} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{ padding: '10px', minWidth: '150px' }}  >
                        <SelectInput name={'costRatio'}
                            handleChange={handleChange} label={translator('calculator.costRatio')}
                            value={calculatorObj.costRatio} defaultValue={40}
                            options={costRatios} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}  sx={{ padding: '10px', minWidth: '150px' }}  >
                        <SelectInput name={'IPA'}
                            handleChange={handleChange} label={translator('calculator.IPA')}
                            value={calculatorObj.IPA} defaultValue={1}
                            options={ipaValues} />
                    </Grid>
            </Grid>
            <Grid container style={{ marginTop: '15px' }} alignItems="center" justifyContent="center" >
                {displayAdvancedButton && <Grid item  sm={3}>
                    {<Button style={{ width: '100%', padding: '10px', fontSize: '1.3em' }}  color='primary' variant="contained" size="large" 
                        onClick={() => {
                            changeButtonTitle()
                            setMonthly()
                            showMonthlyWagesTab()
                        }
                    }><span>Árbevétel<span id="dailyOrYearlyTitle" style={{color:"#FFFF77"}}>{dailyOrYearlyTitle}</span>bontásban</span></Button>}
                </Grid>}
                {!displayAdvancedButton && <Grid item  sm={3}>
                    {showButton && <Button style={{ width: '100%', padding: '10px', fontSize: '1.3em' }} color='primary' variant="contained" size="large" 
                        onClick={() => {
                            displayTable(); 
                            setDisplayAdvancedButton(!displayAdvancedButton)
                        }
                    }>{translator('buttons.simple')}</Button>}
                </Grid>}
            </Grid>
        </Grid>
    )
}

export default Calculator