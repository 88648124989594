export enum LEGAL_RELATIONSHIP {
    MAIN_OCCUPATION = 'Főfoglalkozású',
    SIDE_JOB = 'Mellékfoglalkozású',
    ADDITIONAL_RETIRED = 'Kieg.tevékenységű (nyugdíjas)'
}

export enum MONTH {
    JANUARY = 'Január',
    FEBRUARY = 'Február',
    MARCH = 'Március',
    APRIL = 'Április',
    MAY = 'Május',
    JUNE = 'Június',
    JULY = 'Július',
    AUGUST = 'Augusztus',
    SEPTEMBER = 'Szeptember',
    OCTOBER = 'Október',
    NOVEMBER = 'November',
    DECEMBER = 'December',
}

export enum MONTH_SHORT {
    JANUARY = 'Jan',
    FEBRUARY = 'Feb',
    MARCH = 'Már',
    APRIL = 'Ápr',
    MAY = 'Máj',
    JUNE = 'Jún',
    JULY = 'Júl',
    AUGUST = 'Aug',
    SEPTEMBER = 'Szep',
    OCTOBER = 'Okt',
    NOVEMBER = 'Nov',
    DECEMBER = 'Dec',
}

export enum MONTH_FIELD {
    YEARLY = 'yearly',
    JANUARY = 'january',
    FEBRUARY = 'february',
    MARCH = 'march',
    APRIL = 'april',
    MAY = 'may',
    JUNE = 'june',
    JULY = 'july',
    AUGUST = 'august',
    SEPTEMBER = 'september',
    OCTOBER = 'october',
    NOVEMBER = 'november',
    DECEMBER = 'december',
}

export enum MINIMUMWAGE {
    MINIMUMWAGE = 'Minimálbér (szakképesítést nem igénylő munka)',
    GUARANTEED_MINIMUMWAGE = 'Bérminimum (szakképesítést igénylő munka)'
}