import {CalculatorObj} from '../types/CalculatorObj';
import {MonthlyWagesObj} from '../types/MonthlyWagesObj';
import {Fragment, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {mobileCheck} from './helpers/DeviceCheck';
import { MONTH_FIELD} from '../globals/enums';
import {IIndexable} from '../utils/IIndexable';
import translator from '../translator';
import {getStartingMonthIndexInMonths, monthFields, months, monthShort} from '../globals/constants';
import {v4 as uuid} from "uuid";
import {styled} from '@mui/material';
import {getArrayAsObject,getLastIPAAdat, preCalcAll} from "./MonthlyWages";

type Props = {
  calculatorObj: CalculatorObj,
  monthlyWagesObj: MonthlyWagesObj
  show: boolean
}

const CalculatorTable = ({ calculatorObj, monthlyWagesObj, show }: Props) => {
  const [ isMobile, setIsMobile ] = useState(false);
  const [ windowSize, setWindowSize ] = useState(0);
  
  useEffect(() => {
    window.addEventListener("resize", updateWindowWidth);
  }, []);

  const updateWindowWidth = () => {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    
    if(windowSize <= 950)
      setIsMobile(true);
     if(windowSize > 950 && mobileCheck() == false)
      setIsMobile(false);
  }, [windowSize]);
  
  useEffect(() => setIsMobile(mobileCheck()), [])
  
  useEffect(() => {
    document.getElementById(":r1:")?.focus()
  }, [])

  const formatNumber = (value: number): string => {
    return isNaN(value) || value === 0 ? '- ' : "" + Math.round(value).toLocaleString(navigator.language, {minimumFractionDigits: 0}).replaceAll(',', ' ')
  }

  const removeDigitsSpacesMinusSigns = (removeFrom: string): string => {
    return removeFrom.replace(/[^0-9- ]/gm, '')
  }

  const addCurrencyAfterFormattedNumber = (value: number): string => {
    let formattedNumber = formatNumber(value)
    return formattedNumber.length === removeDigitsSpacesMinusSigns(formattedNumber).length ? " Ft" : ""
  }

  const sumYearly = (obj: MonthlyWagesObj) => {
    let sumOfAllValues = 0;
    Object.entries(obj).forEach(([key, value]) => {
      sumOfAllValues += Number(value);
    });
    return sumOfAllValues;
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: isMobile ? '1em' :'1vw',
      fontWeight: '500'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isMobile ? '1em' :'1vw',
      fontWeight: '500'
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.primary.light,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getTableRow = (obj: MonthlyWagesObj, headerString: string, month?: MONTH_FIELD, isRecurringIncomeField?: boolean, isSzja?: boolean) => {
    if (month) // if mobileView
      return (
          <StyledTableRow key={uuid()}>
            <StyledTableCell align="left" size='small' sx={{fontWeight: 'bold', width: isMobile ? '40%' : '20%'}}>{translator(headerString)}</StyledTableCell>
            {month === 'yearly' && !isRecurringIncomeField ? <StyledTableCell sx={{}} align="right">{formatNumber(sumYearly(obj))}<span className='FtClass'>{addCurrencyAfterFormattedNumber(sumYearly(obj))}</span></StyledTableCell> :
                <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right" key={uuid()}>{formatNumber((obj as IIndexable)[month])}<span className='FtClass'>{addCurrencyAfterFormattedNumber((obj as IIndexable)[month])}</span></StyledTableCell>}
          </StyledTableRow>)

    return (<StyledTableRow key={uuid()}>
      <StyledTableCell padding='none' align="left" size='small' sx={{width: isMobile ? '40%' : '8%', paddingLeft: 1, paddingTop: 0.25, paddingBottom: 0.25, paddingRight: 0}}>{translator(headerString)}</StyledTableCell>
      {!isRecurringIncomeField ? <StyledTableCell className="tabCellCl1" padding='none' align="right" sx={{borderRight: '0px dotted rgba(0,0,0,0.2)', paddingRight: 0.5}}>{formatNumber(sumYearly(obj))}<span className='FtClass'>{addCurrencyAfterFormattedNumber(sumYearly(obj))}</span></StyledTableCell> : <StyledTableCell className="tabCellCl1" sx={isMobile ? {} : {fontSize: '1vw !important', paddingRight: 1, fontWeight: '400'}} width="10%" align="right" >- </StyledTableCell>}
      {Object.entries(obj).map(([key, value]) => {
        return <StyledTableCell className="tableRowDesktop tabCellCl1" style={{paddingRight: '10px'}} padding='none' size='medium' sx={isMobile ? {fontWeight: '300 !important'} : {fontSize: '1.05vw !important', fontWeight: '400 !important', paddingRight: 0}} align="right" key={uuid()}>{formatNumber(value)}<span className='FtClass'>{addCurrencyAfterFormattedNumber(value)}</span></StyledTableCell>      })}
    </StyledTableRow>)
  }

  const getTableRowHipa = (hipa: number) => {
    return (
        <StyledTableRow>
          <StyledTableCell align="left" size='small'
                           sx={{paddingLeft: 1, fontWeight: 'bold', width: isMobile ? '40%' : '20%'}}>HIPA<br/><span
              style={{
                fontWeight: 'normal',
                fontSize: '0.7em'
              }}>(egyszerűsített adóalap alapján)</span></StyledTableCell>
          <StyledTableCell sx={{}} align="right">{formatNumber(hipa)}<span
              className='FtClass'>{addCurrencyAfterFormattedNumber(hipa)}</span></StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
          <StyledTableCell width={isMobile ? 'auto' : "10%"} align="right">&nbsp;</StyledTableCell>
        </StyledTableRow>)
  }

  const getTableRows = (month?: MONTH_FIELD) => {
    preCalcAll(calculatorObj, monthlyWagesObj)
    let rows = [ 
      getTableRow(getArrayAsObject("haviBevételek"), "table.income", month),
      getTableRow(getArrayAsObject("haviKöltségarányok"), "table.costRatio", month),
      getTableRow(getArrayAsObject("haviJövedelmek"), "table.revenue", month),
      getTableRow(getArrayAsObject("haviJövedelmekGöngyölt"), "table.recurringIncome", month, true),
      getTableRow(getArrayAsObject("haviSZJA"), "table.SZJA", month, false, true),
      getTableRow(getArrayAsObject("haviTB"), "table.TB", month),
      getTableRow(getArrayAsObject("haviSZOCHOAdatok"), "table.SZOCHO", month),
      // getTableRow(getArrayAsObject("haviIPAAdatok"), "table.IPA", month),
      // getTableRow(getArrayAsObject("haviÖsszesKöltségAdatok"), "table.allLiabilities", month),
      getTableRow(getArrayAsObject("haviÖsszesKöltségAdatok"), "table.allLiabilities", month),
      getTableRow(getArrayAsObject("haviNettoAdatok"), "table.netIncome", month), 
      getTableRowHipa(getLastIPAAdat()), 
    ]
    return rows
  }
  
  const getTable = (month?: MONTH_FIELD) => {
    let header: any
    let isMobile = month; // if month has value it's mobile view
    
    if(isMobile) {
      if(month === MONTH_FIELD.YEARLY) 
        header = <StyledTableCell key={uuid()} align="right" sx={{ fontWeight: 'bold', whiteSpace: 'normal' }}>{`${translator('table.periodYear')} / ${calculatorObj.year}`}</StyledTableCell>
      else header = <StyledTableCell key={uuid()} align="right" sx={{ fontWeight: 'bold' }}>{translator(translator(`months.${month}`))}</StyledTableCell>
      
    }
    else header = months.map((value, i) => {
        return <StyledTableCell size='small' style={{paddingRight: '10px'}} className='tabHeaderCl1' padding='none' key={uuid()} align="right" sx={{ fontWeight: 'bold', paddingRight: 0.5 }}>{monthShort[i]}</StyledTableCell>
    })
    return (
    <TableContainer key={uuid()} style={{marginTop: '20px', marginBottom: '30px'}}>
      <Table size='small' sx={{minWidth: isMobile ? 'auto' : '1150px'}}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell width='auto' sx={{paddingTop: 3}}></StyledTableCell>
            {month === undefined && <StyledTableCell key={uuid()} size='small' padding='none' align="right" sx={{ fontWeight: 'bold' }}>{`${translator('table.periodYear')}/${calculatorObj.year}`} </StyledTableCell>}
            {header}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {getTableRows(month)}
        </TableBody>
      </Table>
    </TableContainer>)
  }

  const renderTables = () => {
    if(isMobile) {
      let returnList = []
      let startingMonthIndex = getStartingMonthIndexInMonths(calculatorObj)
      let updatedMonths = monthFields.slice(startingMonthIndex + 1)
      returnList.push(getTable(MONTH_FIELD.YEARLY))
      for(const month of updatedMonths) 
        returnList.push(getTable(month))
      return <Fragment>{returnList}</Fragment>
    }
    return getTable();
  }

  if(!show) return <></>

  return (renderTables())
}

export default CalculatorTable
