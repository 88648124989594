import { FormControl, Select, MenuItem, FormLabel } from "@mui/material"

type Props = {
    handleChange: (e: any) => Promise<void>
    defaultValue: any
    label: string
    hasTextInParenthesis?: boolean
    value: any
    options: any[]
    name: string
    suffix?: string
}

const SelectInput = ({ handleChange, defaultValue, label, hasTextInParenthesis, value, options, name, suffix }: Props) => {
    return (
        <FormControl fullWidth>
            <FormLabel id="demo-controlled-radio-buttons-group" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', fontSize: '1em', textAlign: 'center' }}>{label}</FormLabel>
            <Select
                name={name}
                labelId="demo-simple-select-label"
                size="small"
                id="demo-simple-select"
                value={value ?? defaultValue}
                onChange={handleChange}
                variant="outlined"
            >   
                {options.map((option, key) => (
                    hasTextInParenthesis ? 
                        <MenuItem key={key} value={option}> 
                            {(option as string).split('(')[0]}<span style={{color: '#c0c0c0'}}> {(option as string).substring((option as string).indexOf("(") , (option as string).lastIndexOf(")")+1)}</span>
                        </MenuItem>  :
                    <MenuItem key={key} value={option}>{suffix ? (`${option} ${suffix}`) : option}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectInput