import _ from "lodash";
import { garantáltBérminimum, minimálBér } from "../globals/constants";
import { MINIMUMWAGE, LEGAL_RELATIONSHIP, MONTH } from "../globals/enums";
import { CalculatorObj } from "../types/CalculatorObj";
import { MonthlyWagesObj } from "../types/MonthlyWagesObj";
import { TableValues } from "../types/TableValues";

export const emptyCalculatorObj = {
    minimumWage: minimálBér,
    expectedSalesPerYear: 6000000,
    guaranteedMinimumWage: garantáltBérminimum,
    year: new Date().getFullYear(),
    dateDisplay: new Date().getFullYear()+' '+MONTH.JANUARY,
    useMinimumWage: MINIMUMWAGE.MINIMUMWAGE,
    legalRelationShip: LEGAL_RELATIONSHIP.MAIN_OCCUPATION,
    costRatio: 40, 
    IPA: 1,
    startingMonth: MONTH.JANUARY,
} as CalculatorObj



export const emptyMonthlyWagesObj = {
    january: 0,
    february: 0,
    march: 0,
    april: 0,
    may: 0,
    june: 0,
    july: 0,
    august: 0,
    september: 0,
    october: 0,
    november: 0,
    december: 0,
} as MonthlyWagesObj

export const emptyTableValuesObj = {
    costRatioObj: _.cloneDeep(emptyMonthlyWagesObj) as MonthlyWagesObj,
    ipaObj: _.cloneDeep(emptyMonthlyWagesObj) as MonthlyWagesObj,
    tbObj: _.cloneDeep(emptyMonthlyWagesObj) as MonthlyWagesObj,
    szjaObj: _.cloneDeep(emptyMonthlyWagesObj) as MonthlyWagesObj,
    szochoObj: _.cloneDeep(emptyMonthlyWagesObj) as MonthlyWagesObj,
} as TableValues