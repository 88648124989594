import createTheme from "@mui/material/styles/createTheme";

export const mainTheme = createTheme({ 
    palette: { 
      primary: {
        main: '#2966ae',
        light: '#dae7f5'
      }
    },
    typography: {
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: '#fff',
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: '12px',
                boxShadow:'0 0 10px rgba(15,159,237,0.5)',
                border:'1px solid rgba(0,0,0,0.5)'
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '1em'
          },
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            // fontSize:'1vw',
            // fontWeight: '600',
            whiteSpace: 'nowrap',
          },
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            fontSize: '1em',
            color: 'rgba(0, 0, 0, 0.7)'
          },
        }
      },
    }
  })