import { ThemeProvider } from '@emotion/react';
import { useState } from 'react';
import './App.css';
import Calculator from './components/Calculator';
import MonthlyWages from './components/MonthlyWages';
import CalculatorTable from './components/CalculatorTable';
import { emptyCalculatorObj, emptyMonthlyWagesObj } from './utils/emptyObjects';
import _ from 'lodash';
import { mainTheme } from './globals/muiTheme';
import Footer from './components/layout/Footer';
import HideAppBar from './components/layout/Header';
import { CssBaseline } from '@mui/material';

function App() {
  const [ calculatorObj, setCalculatorObj ] = useState(_.cloneDeep(emptyCalculatorObj))
  const [ monthlyWagesObj, setMonthlyWagesObj ] = useState(_.cloneDeep(emptyMonthlyWagesObj))
  const [ showMonthlyWages, setShowMonthlyWages ] = useState(false)
  const [ displayTable, setTableDisplay ] = useState(false)

  const showMonthlyWagesTab = () => setShowMonthlyWages(!showMonthlyWages)

  const displayTableFunc = () => setTableDisplay(!displayTable)

  const [isMonthly, setMonthly] = useState(false);

  const setMonthlyFunc = () => setMonthly(!isMonthly)

  return (
    <div>
      <CssBaseline/>
      <header className="App-header"></header>
        <ThemeProvider theme={mainTheme}>
          <HideAppBar />
          <div style={{marginRight: '15px', marginLeft: '15px'}}>
            <Calculator setMonthly={setMonthlyFunc} isMonthly={isMonthly} calculatorObj={calculatorObj} setCalculatorObj={setCalculatorObj} showMonthlyWagesTab={showMonthlyWagesTab} showButton={!showMonthlyWages} displayTable={displayTableFunc}/>
            <MonthlyWages isMonthly={isMonthly} monthlyWagesObj={monthlyWagesObj} setCalculatorObj={setCalculatorObj} calculatorObj={calculatorObj} setMonthlyWagesObj={setMonthlyWagesObj} show={showMonthlyWages} />
            <CalculatorTable calculatorObj={calculatorObj} monthlyWagesObj={monthlyWagesObj} show={displayTable} />
          </div>
          <Footer />
        </ThemeProvider>
    </div>
  );
}

export default App;
