import {Grid} from "@mui/material"
import {useEffect, useState} from "react"
import {
    getStartingMonthIndexInMonths,
    months,
    monthsAsPropertyNames,
    SZJAMentességHatárértékFt,
    SZJASzázalék,
    SZOCHOMinimálbér,
    setSZOCHOMinimálbér,
    setSZOCHOGarantáltBérminimum,
    SZOCHOGarantáltBérminimum,
    setTBMinimálBér,
    setTBGarantáltBérminimum,
    setMinimálBér,
    setGarantáltBérminimum,
    SZOCHOSzázalék,
    TBMinimálBér,
    TBGarantáltBérminimum,
    TBSzázalék,
    minimálBér,
    garantáltBérminimum,
    minimálBér_2023,
    garantáltBérminimum_2023,
    minimálBér_2023_12,
    garantáltBérminimum_2023_12
} from "../globals/constants"
import {MONTH} from "../globals/enums"
import {CalculatorObj} from "../types/CalculatorObj"
import {MonthlyWagesObj} from "../types/MonthlyWagesObj"
import NumberInput from "./input/NumberInput"

type Props = {
    monthlyWagesObj: MonthlyWagesObj
    isMonthly: boolean
    setCalculatorObj: React.Dispatch<React.SetStateAction<CalculatorObj>>
    calculatorObj: CalculatorObj
    setMonthlyWagesObj: React.Dispatch<React.SetStateAction<MonthlyWagesObj>>
    show: boolean
}

var inputMinimumWageType: string;	//MINIMUMWAGE=szakkepesites NEM igenylo munka / GUARANTEEDMINIMUMWAGE=szakkepesitest IGENYLO
var inputStartDate: string;
var inputExpectedIncome: number;
var inputCostRatioPercent: number;			//40/80/90
var inputIPAKeyPercent: number;				//1/2
var inputLegalRelationshipType: string;		// Főfoglalkozású / Mellékfoglalkozású / Kieg.tevékenységű (nyugdíjas)
var calcObj:any;

/*
Source code translations

feudatory 					= adóköteles
taking 						= bevétel
income 		                = jövedelem
tax-free 					= adómentes
minimum wage 				= minimálbér
guaranteed minimum wage 	= bérminimum
cost ratio                  = költséghányad
rolled up                   = gönygyölt
legal relationship			= jogviszony

 */

var haviBevételek: number[]           = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviJövedelmek: number[]          = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviBevételekGöngyölt: number[]   = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviJövedelmekGöngyölt: number[]  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviKöltségarányok: number[]      = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviSZJAAlapok: number[]          = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviSZJA: number[]                = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviTB: number[]                  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviAdóköteles: number[]          = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviAdókötelesGöngyölt: number[]  = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviTBAlap: number[]              = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviSZOCHOAdatok: number[]        = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviSZOCHOAlapAdatok: number[]    = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviIPAAdatok: number[]           = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviÖsszesKöltségAdatok: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var haviNettoAdatok: number[]         = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

//TODO: Remove eval and reach resultObj's properties dynamically...
export const getArrayAsObject = (arrName: string): MonthlyWagesObj => {
    let resultObj     = {} as MonthlyWagesObj;
    let arr: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let thisMonth;
    // eslint-disable-next-line no-eval
    eval(`arr = ${arrName};`)
    for (let i = 0; i < 12; i++) {
        thisMonth = monthsAsPropertyNames[i];
        // eslint-disable-next-line no-eval
        eval(`resultObj.${thisMonth} = ${arr[i]};`);
    }
    return resultObj
}

export const getLastIPAAdat = (): number => {
    return haviIPAAdatok[11];
}

const getMonthFromDateStringYYYYMMOrHungarianMonthAsText = (startDate: string): number => {
    startDate        = startDate.replaceAll(" ", "-");
    let result       = startDate.split("-", -1)[1];
    result           = result.replace(/^0{0,1}/gm, "");
    let resultNumber = parseInt(result, 10);
    if (isNaN(resultNumber)) {
        let monthBeginnings = ["Ja", "F", "Már", "Á", "Máj", "Jún", "Júl", "A", "Sz", "O", "N", "D"];
        for (let i = 0; i < monthBeginnings.length; i++) {
            if (result.indexOf(monthBeginnings[i]) === 0) {
                return i + 1;
            }
        }
        return 1;
    }
    return resultNumber;
}

const calculateAllRolledUpIncomeValues = (): void => {
    haviJövedelmekGöngyölt[0] = haviJövedelmek[0]
    for (let i = 1; i < 12; i++) {
        haviJövedelmekGöngyölt[i] = haviJövedelmek[i] + haviJövedelmekGöngyölt[i - 1]
    }
}

const calculateHaviGöngyöltBevételek = (): void => {
    haviBevételekGöngyölt[0] = haviBevételek[0]
    for (let i = 1; i < 12; i++) {
        haviBevételekGöngyölt[i] = haviBevételek[i] + haviBevételekGöngyölt[i - 1]
    }
}

const haviÁtlagjövedelmekKiszámítása = (): void => {

    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);
    let totalMonths   = 12 - startingMonth + 1;
    let incomeMonthly = inputExpectedIncome / totalMonths;

    for (let i = 0; i < 12; i++) {
        haviJövedelmekGöngyölt[i] = 0;
    }
    for (let i = 0; i < 12; i++) {
        if (i < 12 - totalMonths) {
            haviBevételek[i]      = 0;
            haviJövedelmek[i]     = 0;
            haviKöltségarányok[i] = 0;
        } else {
            haviBevételek[i]      = incomeMonthly;
            haviJövedelmek[i]     = incomeMonthly * (1 - inputCostRatioPercent / 100);
            haviKöltségarányok[i] = haviBevételek[i] - haviJövedelmek[i];
        }
    }

    calculateAllRolledUpIncomeValues()
    calculateHaviGöngyöltBevételek()
}

const haviEgyediJövedelmekKiszámítása = (monthlyWagesObj: any): void => {

    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);
    let totalMonths   = 12 - startingMonth + 1;
    let thisMonthIncome;

    for (let i = 0; i < 12; i++) {
        if (i < 12 - totalMonths) {
            haviBevételek[i]           = 0;
            haviJövedelmek[i]          = 0;
            haviKöltségarányok[i]      = 0;
            haviJövedelmekGöngyölt[i] = 0;
        } else {
            switch (i) {
                case 0:
                    thisMonthIncome = monthlyWagesObj.january;
                    break;
                case 1:
                    thisMonthIncome = monthlyWagesObj.february;
                    break;
                case 2:
                    thisMonthIncome = monthlyWagesObj.march;
                    break;
                case 3:
                    thisMonthIncome = monthlyWagesObj.april;
                    break;
                case 4:
                    thisMonthIncome = monthlyWagesObj.may;
                    break;
                case 5:
                    thisMonthIncome = monthlyWagesObj.june;
                    break;
                case 6:
                    thisMonthIncome = monthlyWagesObj.july;
                    break;
                case 7:
                    thisMonthIncome = monthlyWagesObj.august;
                    break;
                case 8:
                    thisMonthIncome = monthlyWagesObj.september;
                    break;
                case 9:
                    thisMonthIncome = monthlyWagesObj.october;
                    break;
                case 10:
                    thisMonthIncome = monthlyWagesObj.november;
                    break;
                case 11:
                    thisMonthIncome = monthlyWagesObj.december;
                    break;
                default:
                    break;
            }

            haviBevételek[i]      = (thisMonthIncome);
            haviJövedelmek[i]     = thisMonthIncome * (1 - inputCostRatioPercent / 100);
            haviKöltségarányok[i] = (haviBevételek[i] - haviJövedelmek[i]);

        }
    }

    calculateAllRolledUpIncomeValues()
    calculateHaviGöngyöltBevételek()
}

const isQ1 = (monthNo: number): boolean => {
    return ++monthNo < 4;
}

const isQ2 = (monthNo: number): boolean => {
    return !isQ1(monthNo) && ++monthNo < 7;
}

const isQ3 = (monthNo: number): boolean => {
    return !isQ1(monthNo) && !isQ2(monthNo) && ++monthNo < 10;
}

const isQ4 = (monthNo: number): boolean => {
    return !isQ1(monthNo) && !isQ2(monthNo) && !isQ3(monthNo) && monthNo < 12 && monthNo >= 0;
}

const reCalcSZJASOCHOTBWages = (): void => {

    setSZOCHOMinimálbér(minimálBér * 1.125);
    setSZOCHOGarantáltBérminimum(garantáltBérminimum * 1.125);
    setTBMinimálBér(minimálBér * 1);
    setTBGarantáltBérminimum(garantáltBérminimum * 1);

}


const switchWagesTo2023    = (): void => {

    if (calcObj.year > 2023) {
        switchWagesTo2023_12();
    } else {
        setMinimálBér(minimálBér_2023);
        setGarantáltBérminimum(garantáltBérminimum_2023);
        reCalcSZJASOCHOTBWages();
    }
}
const switchWagesTo2023_12 = (): void => {
    setMinimálBér(minimálBér_2023_12);
    setGarantáltBérminimum(garantáltBérminimum_2023_12);
    reCalcSZJASOCHOTBWages();
}


const haviSZJAlapokKiszámítása = (): void => {
    let upperPart, SZJABase;
    let összJövedelem = 0;
    switchWagesTo2023();
    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate) - 1;
    for (let i = startingMonth; i < 12; i++) {
        if (i === 11) {
            switchWagesTo2023_12();
        }
        összJövedelem += haviJövedelmek[i];
        if (összJövedelem < SZJAMentességHatárértékFt) {
            haviSZJAAlapok[i] = 0;
        } else {
            upperPart         = összJövedelem - SZJAMentességHatárértékFt;
            SZJABase          = Math.min(upperPart, haviJövedelmek[i]);
            haviSZJAAlapok[i] = SZJABase;
            let SZJA          = SZJABase * SZJASzázalék / 100;
            haviSZJA[i]       = SZJA;
        }
    }
}

const whichQuarter = (monthNo: number): number => {
    if (isQ1(monthNo)) {
        return 1;
    }
    if (isQ2(monthNo)) {
        return 2;
    }
    if (isQ3(monthNo)) {
        return 3;
    }
    if (isQ4(monthNo)) {
        return 4;
    }
    return 4;
}

const quarterMaxMonthNo = (monthNo: number): number => {
    // console.log("Mno:"+monthNo);
    switch (monthNo) {
        case 1:
            return 2;
        case 2:
            return 5;
        case 3:
            return 8;
        case 4:
            return 11;
    }
    return -1;
}

const haviTBAlapokKiszámítása_2 = (): void => {

    switchWagesTo2023();
    let baseForCurrentMonth;
    let tmpSum        = 0;
    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);

    let adóköteles_Q1                = haviAdókötelesGöngyölt[2];
    let adóköteles_Q2                = haviAdókötelesGöngyölt[5];
    let adóköteles_Q3                = haviAdókötelesGöngyölt[8];
    let adóköteles_Q4                = haviAdókötelesGöngyölt[11];
    let TBMinimumWageToUse           = 0;
    let TBGuaranteedMinimumWageToUse = 0;
    let minimumWageToUse             = 0;
    startingMonth--;
    for (let i = startingMonth; i < 12; i += 1) {
        if (i === 11) {
            switchWagesTo2023_12();
        }
        minimumWageToUse = inputMinimumWageType === "MINIMUMWAGE" ? SZOCHOMinimálbér : SZOCHOGarantáltBérminimum;
        if (isQ1(i)) {
            tmpSum = adóköteles_Q1 / 3;
            tmpSum = startingMonth === 1 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 2 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ2(i)) {
            tmpSum = (adóköteles_Q2 - (haviTBAlap[0] + haviTBAlap[1] + haviTBAlap[2])) / 3;
            tmpSum = startingMonth === 4 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 5 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ3(i)) {
            tmpSum = (adóköteles_Q3 - (haviTBAlap[0] + haviTBAlap[1] + haviTBAlap[2] + haviTBAlap[3] + haviTBAlap[4] + haviTBAlap[5])) / 3;
            tmpSum = startingMonth === 7 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 8 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ4(i)) {
            tmpSum = (adóköteles_Q4 - (haviTBAlap[0] + haviTBAlap[1] + haviTBAlap[2] + haviTBAlap[3] + haviTBAlap[4] + haviTBAlap[5] + haviTBAlap[6] + haviTBAlap[7] + haviTBAlap[8])) / 3;
            tmpSum = startingMonth === 10 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 11 ? tmpSum * 3 / 1 : tmpSum;
        }
        baseForCurrentMonth = tmpSum < minimumWageToUse ? minimumWageToUse : tmpSum;
        baseForCurrentMonth = inputLegalRelationshipType === "Mellékfoglalkozású" ? tmpSum : baseForCurrentMonth;
        baseForCurrentMonth = inputLegalRelationshipType.indexOf("yugdíj") !== -1 ? 0 : baseForCurrentMonth;
        haviTBAlap[i]       = baseForCurrentMonth;
    }
    for (let i = 0; i < 12; i++) {
        haviSZOCHOAdatok[i] = haviTBAlap[i] * (SZOCHOSzázalék / 100);
    }
}


const haviTBAlapokKiszámítása = (): void => {

    switchWagesTo2023();
    let baseForCurrentMonth;
    let tmpSum        = 0;
    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);

    let adóköteles_Q1                = haviAdókötelesGöngyölt[2];
    let adóköteles_Q2                = haviAdókötelesGöngyölt[5];
    let adóköteles_Q3                = haviAdókötelesGöngyölt[8];
    let adóköteles_Q4                = haviAdókötelesGöngyölt[11];
    let TBMinimumWageToUse           = 0;
    let TBGuaranteedMinimumWageToUse = 0;
    let minimumWageToUse             = 0;
    startingMonth--;
    for (let i = startingMonth; i < 12; i += 1) {
        if (i === 11) {
            switchWagesTo2023_12();
        }
        minimumWageToUse = inputMinimumWageType === "MINIMUMWAGE" ? TBMinimálBér : TBGarantáltBérminimum;
        if (isQ1(i)) {
            tmpSum = adóköteles_Q1 / 3;
            tmpSum = startingMonth === 1 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 2 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ2(i)) {
            tmpSum = (adóköteles_Q2 - (haviTBAlap[0] + haviTBAlap[1] + haviTBAlap[2])) / 3;
            tmpSum = startingMonth === 4 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 5 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ3(i)) {
            tmpSum = (adóköteles_Q3 - (haviTBAlap[0] + haviTBAlap[1] + haviTBAlap[2] + haviTBAlap[3] + haviTBAlap[4] + haviTBAlap[5])) / 3;
            tmpSum = startingMonth === 7 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 8 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ4(i)) {
            tmpSum = (adóköteles_Q4 - (haviTBAlap[0] + haviTBAlap[1] + haviTBAlap[2] + haviTBAlap[3] + haviTBAlap[4] + haviTBAlap[5] + haviTBAlap[6] + haviTBAlap[7] + haviTBAlap[8])) / 3;
            tmpSum = startingMonth === 10 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 11 ? tmpSum * 3 / 1 : tmpSum;
        }
        baseForCurrentMonth = tmpSum < minimumWageToUse ? minimumWageToUse : tmpSum;
        baseForCurrentMonth = inputLegalRelationshipType === "Mellékfoglalkozású" ? tmpSum : baseForCurrentMonth;
        baseForCurrentMonth = inputLegalRelationshipType.indexOf("yugdíj") !== -1 ? 0 : baseForCurrentMonth;
        haviTBAlap[i]       = baseForCurrentMonth;
    }
    for (let i = 0; i < 12; i++) {
        haviTB[i] = haviTBAlap[i] * (TBSzázalék / 100);
    }
}

const haviAdókötelesAdatokKiszámítása = (): void => {
    let SZJAMentességFt;
    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);
    switchWagesTo2023();
    for (let i = startingMonth - 1, jövedelemSumma = 0; i < 12; i++) {
        if (i === 11) {
            switchWagesTo2023_12();
        }
        jövedelemSumma += haviJövedelmek[i];

        if (jövedelemSumma < SZJAMentességHatárértékFt) {
            haviAdóköteles[i] = 0;
        } else {
            SZJAMentességFt = jövedelemSumma - SZJAMentességHatárértékFt;
            if (SZJAMentességFt > haviJövedelmek[i]) {
                SZJAMentességFt = haviJövedelmek[i];
            }
            haviAdóköteles[i] = SZJAMentességFt;
        }
        if (i > 0) {
            haviAdókötelesGöngyölt[i] = haviAdókötelesGöngyölt[i - 1] + haviAdóköteles[i];
        } else {
            haviAdókötelesGöngyölt[i] = haviAdóköteles[i];
        }
    }
}

const haviSZOCHOAlapokKiszámítása = (): void => {

    console.log(haviAdóköteles)

    switchWagesTo2023();
    let baseForCurrentMonth;
    let tmpSum        = 0;
    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);

    let adóköteles_Q1                    = haviAdókötelesGöngyölt[2];
    let adóköteles_Q2                    = haviAdókötelesGöngyölt[5];
    let adóköteles_Q3                    = haviAdókötelesGöngyölt[8];
    let adóköteles_Q4                    = haviAdókötelesGöngyölt[11];
    let SZOCHOMinimumWageToUse           = 0;
    let SZOCHOGuaranteedMinimumWageToUse = 0;
    let minimumWageToUse                 = 0;
    startingMonth--;
    for (let i = startingMonth; i < 12; i += 1) {
        if (i === 11) {
            switchWagesTo2023_12();
        }
        minimumWageToUse = inputMinimumWageType === "MINIMUMWAGE" ? SZOCHOMinimálbér : SZOCHOGarantáltBérminimum;
        if (isQ1(i)) {
            tmpSum = adóköteles_Q1 / 3;
            tmpSum = startingMonth === 1 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 2 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ2(i)) {
            tmpSum = (adóköteles_Q2 - (haviSZOCHOAlapAdatok[0] + haviSZOCHOAlapAdatok[1] + haviSZOCHOAlapAdatok[2])) / 3;
            tmpSum = startingMonth === 1 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 2 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ3(i)) {
            tmpSum = (adóköteles_Q3 - (haviSZOCHOAlapAdatok[0] + haviSZOCHOAlapAdatok[1] + haviSZOCHOAlapAdatok[2] + haviSZOCHOAlapAdatok[3] + haviSZOCHOAlapAdatok[4] + haviSZOCHOAlapAdatok[5])) / 3;
            tmpSum = startingMonth === 7 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 8 ? tmpSum * 3 / 1 : tmpSum;
        }
        if (isQ4(i)) {
            tmpSum = (adóköteles_Q4 - (haviSZOCHOAlapAdatok[0] + haviSZOCHOAlapAdatok[1] + haviSZOCHOAlapAdatok[2] + haviSZOCHOAlapAdatok[3] + haviSZOCHOAlapAdatok[4] + haviSZOCHOAlapAdatok[5] + haviSZOCHOAlapAdatok[6] + haviSZOCHOAlapAdatok[7] + haviSZOCHOAlapAdatok[8])) / 3;
            tmpSum = startingMonth === 10 ? tmpSum * 3 / 2 : tmpSum;
            tmpSum = startingMonth === 11 ? tmpSum * 3 / 1 : tmpSum;
        }
        baseForCurrentMonth     = tmpSum < minimumWageToUse ? minimumWageToUse : tmpSum;
        baseForCurrentMonth = tmpSum < minimumWageToUse ? minimumWageToUse : tmpSum;
        baseForCurrentMonth = inputLegalRelationshipType === "Mellékfoglalkozású" ? tmpSum : baseForCurrentMonth;
        baseForCurrentMonth = inputLegalRelationshipType.indexOf("yugdíj") !== -1 ? 0 : baseForCurrentMonth;

        haviSZOCHOAlapAdatok[i] = baseForCurrentMonth;
    }
    for (let i = 0; i < 12; i++) {
        haviSZOCHOAdatok[i] = (haviSZOCHOAlapAdatok[i] * (SZOCHOSzázalék / 100));
    }
}

const haviIPAAdatokKiszámítása = (): void => {
    for (let i = 0; i < 12; i++) {
        let currentGöngyöltBevétel = haviBevételekGöngyölt[i];
        let adóalap = 8500000;
        if (currentGöngyöltBevétel <= 12000000) {
            adóalap = 2500000;
        } else {
            if (currentGöngyöltBevétel <= 18000000) {
                adóalap = 6000000;
            } else {
                if (currentGöngyöltBevétel <= 25000000) {
                    adóalap = 8500000;
                }
            }
        }
        if (currentGöngyöltBevétel > 120000000) {
            adóalap = 0;
        }
        adóalap *= inputIPAKeyPercent / 100
        haviIPAAdatok[i] = adóalap;
        // haviIPAAdatok[i] = (haviBevételek[i] * 1.2 * inputIPAKeyPercent / 100);
    }
}

const haviKiadásokKiszámítása = (): void => {
    for (let i = 0; i < 12; i++) {
        let currentExpense         = 0;
        currentExpense += haviSZJA[i]
        currentExpense += haviTB[i]
        currentExpense += haviSZOCHOAdatok[i]
        currentExpense += haviIPAAdatok[i]
        haviÖsszesKöltségAdatok[i] = currentExpense;
        haviNettoAdatok[i]         = haviBevételek[i] - currentExpense;
    }
}

const clearBlankMonths = (): void => {
    let startingMonth = getMonthFromDateStringYYYYMMOrHungarianMonthAsText(inputStartDate);
    for (let i = startingMonth - 1; i > 0; i--) {
        haviSZJA[--i]              = 0;
        haviSZJAAlapok[--i]        = 0;
        haviTB[i]                  = 0;
        haviSZOCHOAdatok[i]        = 0;
        haviIPAAdatok[i]           = 0;
        haviÖsszesKöltségAdatok[i] = 0;
        haviNettoAdatok[i++]       = 0;
    }
}


export const preCalcAll = (calculatorObj: any, monthlyWagesObjInstance: any): void => {
    calcObj = calculatorObj;
    console.clear();

    switchWagesTo2023();
    clearArrays();
    inputExpectedIncome        = calculatorObj.expectedSalesPerYear
    inputIPAKeyPercent         = calculatorObj.IPA
    inputCostRatioPercent      = calculatorObj.costRatio
    inputLegalRelationshipType = calculatorObj.legalRelationShip
    inputStartDate             = calculatorObj.dateDisplay;
    inputMinimumWageType       = calculatorObj.useMinimumWage;
    inputMinimumWageType       = inputMinimumWageType.toUpperCase();
    inputMinimumWageType       = inputMinimumWageType.indexOf('MINIMÁLBÉR') !== -1 ? "MINIMUMWAGE" : "GUARANTEEDMINIMUMWAGE";
    let inputCount             = document.querySelectorAll("input[type=text]").length;

    if (inputCount === 1) {
        haviÁtlagjövedelmekKiszámítása();
    } else {
        haviEgyediJövedelmekKiszámítása(monthlyWagesObjInstance);
    }

    // console.log();
    haviSZJAlapokKiszámítása();
    haviAdókötelesAdatokKiszámítása();
    haviTBAlapokKiszámítása();
    haviTBAlapokKiszámítása_2();
    //haviSZOCHOAlapokKiszámítása();
    haviKiadásokKiszámítása()
    haviIPAAdatokKiszámítása();

    clearBlankMonths()

    // printDebugData()
}
const printDebugData    = (): void => {
    console.log("haviBevételek");
    console.log(haviBevételek);
    console.log("haviJövedelmek");
    console.log(haviJövedelmek);
    console.log("haviKöltségarányok");
    console.log(haviKöltségarányok);
    console.log("haviJövedelmekGöngyölt");
    console.log(haviJövedelmekGöngyölt);
    console.log("haviSZJAAlapok");
    console.log(haviSZJAAlapok);
    console.log("haviAdóköteles");
    console.log(haviAdóköteles);
    console.log("haviAdókötelesGöngyölt");
    console.log(haviAdókötelesGöngyölt);
    console.log("haviTBAlap");
    console.log(haviTBAlap);
    console.log("haviTB");
    console.log(haviTB);
    console.log("haviSZOCHOAdatok");
    console.log(haviSZOCHOAdatok);
    console.log("haviIPAAdatok");
    console.log(haviIPAAdatok);
    console.log("haviNettoAdatok");
    console.log(haviNettoAdatok);
    console.log("haviÖsszesKöltségAdatok");
    console.log(haviÖsszesKöltségAdatok);
}

const clearArrays = (): void => {
    for (let i = 0; i < 12; i++) {
        haviBevételek[i]           = 0;
        haviJövedelmek[i]          = 0;
        haviJövedelmekGöngyölt[i] = 0;
        haviKöltségarányok[i]      = 0;
        haviSZJA[i]                = 0;
        haviSZJAAlapok[i]          = 0;
        haviTB[i]                  = 0;
        haviAdóköteles[i]          = 0;
        haviAdókötelesGöngyölt[i]  = 0;
        haviTBAlap[i]              = 0;
        haviSZOCHOAdatok[i]        = 0;
        haviSZOCHOAlapAdatok[i]    = 0;
        haviIPAAdatok[i]           = 0;
        haviÖsszesKöltségAdatok[i] = 0;
        haviNettoAdatok[i]         = 0;
    }
}

const MonthlyWages = ({monthlyWagesObj, setCalculatorObj, calculatorObj, setMonthlyWagesObj, show}: Props) => {
    const [startingMonthIndex, setStartingMonthIndex] = useState(0);
    const [monthly, setMonthly]                       = useState(false);
    useEffect(() => {
        calculateMonthlyWages();
        setStartingMonthIndex(displayMonthlyWages(calculatorObj.startingMonth));
    }, [calculatorObj.expectedSalesPerYear, calculatorObj.startingMonth, show])

    const displayMonthlyWages = (startingMonth: string) => {

        let monthIndex = 0;
        if (startingMonth === MONTH.JANUARY) return monthIndex;

        let index = 0;
        months.forEach(month => {
            if (month === startingMonth) monthIndex = index;
            index++;
        });

        return monthIndex;
    }

    const calculateMonthlyWages = async () => {

        let startingMonthIndex     = getStartingMonthIndexInMonths(calculatorObj)
        let updatedMonths          = months.slice(startingMonthIndex)
        const partialExpectedSales = calculatorObj.expectedSalesPerYear / updatedMonths.length

        setMonthlyWagesObj(prevState => ({
            ...prevState,
            january: updatedMonths.includes(MONTH.JANUARY) ? partialExpectedSales : 0,
            february: updatedMonths.includes(MONTH.FEBRUARY) ? partialExpectedSales : 0,
            march: updatedMonths.includes(MONTH.MARCH) ? partialExpectedSales : 0,
            april: updatedMonths.includes(MONTH.APRIL) ? partialExpectedSales : 0,
            may: updatedMonths.includes(MONTH.MAY) ? partialExpectedSales : 0,
            june: updatedMonths.includes(MONTH.JUNE) ? partialExpectedSales : 0,
            july: updatedMonths.includes(MONTH.JULY) ? partialExpectedSales : 0,
            august: updatedMonths.includes(MONTH.AUGUST) ? partialExpectedSales : 0,
            september: updatedMonths.includes(MONTH.SEPTEMBER) ? partialExpectedSales : 0,
            october: updatedMonths.includes(MONTH.OCTOBER) ? partialExpectedSales : 0,
            november: updatedMonths.includes(MONTH.NOVEMBER) ? partialExpectedSales : 0,
            december: updatedMonths.includes(MONTH.DECEMBER) ? partialExpectedSales : 0
        }))
    }

    const handleChange = async (e: any, name: string) => {

        setMonthlyWagesObj(prevState => ({
            ...prevState,
            [name]: Number((e.target.value).replaceAll(' ', ''))
        }))
    }

    if (!show) return <></>

    return (
        <Grid sx={{width: '100%'}} alignItems="center" justifyContent="center" container>
            {startingMonthIndex <= 0 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'january'}
                             value={monthlyWagesObj.january}
                             handleInput={handleChange}
                             inputProps={MONTH.JANUARY}
                />
            </Grid>}
            {startingMonthIndex <= 1 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'february'}
                             value={monthlyWagesObj.february}
                             handleInput={handleChange}
                             inputProps={MONTH.FEBRUARY}/>
            </Grid>}
            {startingMonthIndex <= 2 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'march'}
                             value={monthlyWagesObj.march}
                             handleInput={handleChange}
                             inputProps={MONTH.MARCH}/>
            </Grid>}
            {startingMonthIndex <= 3 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'april'}
                             value={monthlyWagesObj.april}
                             handleInput={handleChange}
                             inputProps={MONTH.APRIL}/>
            </Grid>}
            {startingMonthIndex <= 4 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'may'}
                             value={monthlyWagesObj.may}
                             handleInput={handleChange}
                             inputProps={MONTH.MAY}/>
            </Grid>}
            {startingMonthIndex <= 5 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'june'}
                             value={monthlyWagesObj.june}
                             handleInput={handleChange}
                             inputProps={MONTH.JUNE}
                             monthNumber={6}/>
            </Grid>}
            {startingMonthIndex <= 6 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'july'}
                             value={monthlyWagesObj.july}
                             handleInput={handleChange}
                             inputProps={MONTH.JULY}
                             monthNumber={7}/>
            </Grid>}
            {startingMonthIndex <= 7 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'august'}
                             value={monthlyWagesObj.august}
                             handleInput={handleChange}
                             inputProps={MONTH.AUGUST}/>
            </Grid>}
            {startingMonthIndex <= 8 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'september'}
                             value={monthlyWagesObj.september}
                             handleInput={handleChange}
                             inputProps={MONTH.SEPTEMBER}/>
            </Grid>}
            {startingMonthIndex <= 9 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'october'}
                             value={monthlyWagesObj.october}
                             handleInput={handleChange}
                             inputProps={MONTH.OCTOBER}/>
            </Grid>}
            {startingMonthIndex <= 10 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'november'}
                             value={monthlyWagesObj.november}
                             handleInput={handleChange}
                             inputProps={MONTH.NOVEMBER}/>
            </Grid>}
            {startingMonthIndex <= 11 && <Grid item xs={12} sm={6} md={4} lg={3} sx={{padding: '10px'}}>
                <NumberInput name={'december'}
                             value={monthlyWagesObj.december}
                             handleInput={handleChange}
                             inputProps={MONTH.DECEMBER}/>
            </Grid>}
        </Grid>)
}

export default MonthlyWages
