import * as React from 'react';

import { Grid, Paper, Typography } from '@mui/material';

const Footer = () => {


// „A HIPA számítására teljes naptári évre vonatkozóan kerül sor, napi arányosítást a kalkulátor nem kezel!
//
//     25 millió forint felett 120 millió forint bevételig a kizárólag kiskereskedelmi tevékenységet végző átalányadózók esetében érvényes a kalkulált éves HIPA összeg.”    
    
    
    return (
        <Paper elevation={3} style={{padding: '15px', margin: '10px', opacity:'0.7'}}>
            <Typography variant='caption'>
                <h3>A HIPA számításáról</h3>A HIPA számítására teljes naptári évre vonatkozóan kerül sor, napi arányosítást a kalkulátor nem kezel! 25 millió forint felett 120 millió forint bevételig a kizárólag kiskereskedelmi tevékenységet végző átalányadózók esetében érvényes a kalkulált éves HIPA összeg.<br/><br/>
            </Typography>
            <Typography variant='caption'>
                <h3>Költséghányad</h3><b>A 80%-os költséghányad akkor választható</b>, ha a vállalkozó az adóévben kizárólag az alábbi listán szereplő és kiskereskedelmi tevékenységből szerez bevételt:
            </Typography>
            <ul>
                <li><Typography variant='caption'>mezőgazdasági, erdőgazdálkodási (TESZOR 01, 02), bányászati (TESZOR 05-től 09-ig) és feldolgozóipari (TESZOR 10-től 32-ig) termék-előállítás, építőipari kivitelezés (TESZOR 41, 42)</Typography></li>
                <li><Typography variant='caption'>
                    mezőgazdasági, betakarítást követő szolgáltatás (TESZOR 01.6), vadgazdálkodáshoz kapcsolódó szolgáltatás (TESZOR 01.70.10), erdészeti szolgáltatás (TESZOR 02.40.10) és zöldterület-kezelés (TESZOR 81.30.10)</Typography></li>
                <li><Typography variant='caption'>
                    halászati szolgáltatás (TESZOR 03.00.71), halgazdálkodási szolgáltatás (TESZOR 03.00.72)</Typography></li>
                <li><Typography variant='caption'>
                    feldolgozóipari szolgáltatás (TESZOR 10-től 32-ig) a bérmunkában végzett szolgáltatás és az egyéb sokszorosítás (TESZOR 18.20) kivételével</Typography></li>
                <li><Typography variant='caption'>
                    építőipari szolgáltatás (TESZOR 43)</Typography></li>
                <li><Typography variant='caption'>
                    ipari gép, berendezés, eszköz javítása (TESZOR 33.1), gépjárműjavítás (TESZOR 45.20), személyi, háztartási cikk javítása (TESZOR 95.2), épületgépészeti berendezések javítása (TESZOR 43.21, 43.22, 43.29)</Typography></li>
                <li><Typography variant='caption'>
                    a taxis személyszállítás (TESZOR 49.32.11) személygépjármű kölcsönzése vezetővel (TESZOR 49.32.12), egyéb máshová nem sorolt szárazföldi személyszállítás (TESZOR 49.39.39), közúti áruszállítás (TESZOR 49.41.1)</Typography></li>
                <li><Typography variant='caption'>
                    számítógép, kommunikációs eszköz javítása (TESZOR 95.1)</Typography></li>
                <li><Typography variant='caption'>
                    fényképészet (TESZOR 74.20)</Typography></li>
                <li><Typography variant='caption'>
                    textil, szőrme mosása, tisztítása (TESZOR 96.01), fodrászat, szépségápolás (TESZOR 96.02), hobbiállat-gondozás (TESZOR 96.09.11)</Typography></li>
                <li><Typography variant='caption'>
                    a kereskedelmi tevékenységek végzésének feltételeiről szóló kormányrendelet alapján folytatott vendéglátó tevékenység (TESZOR 56)</Typography></li>
            </ul>
            <br />
            <Typography variant='caption'>
                A <b>90%-os költséghányad akkor választható</b>, ha a vállalkozó az adóév egészében kizárólag a kereskedelmi tevékenységek végzésének feltételeiről szóló kormányrendelet alapján kiskereskedelmi tevékenységből szerez bevételt.<br />
            </Typography>
        </Paper>
    )
}

export default Footer